<template>
  <v-card class="mt-1 wrapper">
    <div class="info-box">
      <!-- WARNINGS -->
      <v-row v-if="!currentContract.baseInvoice.active" dense>
        <v-col class="warning rounded">
          <p class="mb-1">
            Tämän sopimuksen toistuvaislasku {{ currentContract.baseInvoice.invoiceNumber }} on pois
            käytöstä (ei aktiivisena). Et voi jatkaa vuokrankorotuksen tekemistä.
          </p>
        </v-col>
      </v-row>
      <v-row v-if="currentContract.baseInvoice.measurementsNeeded" dense>
        <v-col class="warning rounded">
          <p class="mb-1">
            Toistuvaislaskulla {{ currentContract.baseInvoice.invoiceNumber }} on havaittu virhe.
            Korjaa virhe kohdasta "Laskutus" -> "Laskut" -> "Vaatii toimenpiteitä", jotta voit
            jatkaa.
          </p>
        </v-col>
      </v-row>

      <v-row dense v-if="fixedPeriodRecurrencyInUse">
        <v-col class="warning rounded">
          <p class="mb-1">
            Laskussa on käytössä toistuvien tuotteiden määräaikaisuus. Mikäli tallennat
            vuokrankorotuksen, määräaikaiset tuotteet poistetaan käytöstä välittömästi.
          </p>
        </v-col>
      </v-row>

      <!-- INFO -->
      <v-row dense>
        <v-col cols="12">
          <h2 class="mb-1">Sopimuksen tiedot</h2>
        </v-col>
      </v-row>

      <v-row dense>
        <v-col cols="12" sm="9" md="6">
          <div>
            <h3 class="mb-1">Sopimus</h3>

            <div class="info-text-wrapper">
              <p class="text-box-left">Sopimus alkaa:</p>
              <p class="text-box-right">
                {{ formatDate(currentContract.lease.startDate) }}
              </p>
            </div>

            <div
              v-if="
                currentContract.lease.endDate &&
                currentContract.lease.leaseType == 'Määräaikainen' &&
                !currentContract.revokedDate
              "
              class="info-text-wrapper"
            >
              <p class="text-box-left">Sopimus päättyy:</p>
              <p class="text-box-right">
                {{ formatDate(currentContract.lease.endDate) }}
              </p>
            </div>

            <div v-if="currentContract.lease.revokedDate" class="info-text-wrapper">
              <p class="text-box-left">Sopimus päättyy:</p>
              <p class="text-box-right">
                {{ formatDate(currentContract.revokedDate) }}
              </p>
            </div>

            <h3 class="mb-1 mt-2">Vuokrankorotus</h3>

            <div class="info-text-wrapper">
              <p class="text-box-left">Seuraavan korotuksen pvm.:</p>
              <p class="text-box-right">
                {{ formatDate(currentContract.lease.nextRentIncreaseDate) }}
              </p>
            </div>

            <div class="info-text-wrapper">
              <p class="text-box-left">Korotuksen peruste:</p>
              <p class="text-box-right">
                {{ getRentIncreaseMethod(currentContract.lease.rentIncreaseType) }}
              </p>
            </div>

            <div class="info-text-wrapper">
              <p class="text-box-left">Korotus:</p>
              <p class="text-box-right">
                {{ getMethodBasedValues }}
              </p>
            </div>

            <div v-if="currentContract.lease.rentIncreaseComment" class="info-text-wrapper">
              <p class="text-box-left">Kommentit</p>
              <p class="text-box-right">
                {{ currentContract.lease.rentIncreaseComment }}
              </p>
            </div>
          </div>
        </v-col>
      </v-row>

      <v-divider class="mb-2 mt-1 mx-2"></v-divider>

      <!-- FORM -->
      <Form ref="form"></Form>

      <v-row dense>
        <v-col cols="12">
          <h2 class="mt-2">Esikatselu</h2>
        </v-col>
      </v-row>

      <v-row dense>
        <v-col cols="12" sm="5">
          <!-- Prices -->
          <Prices productType="currentProducts"></Prices>
        </v-col>

        <v-col align="center" cols="12" sm="2"
          ><div class="arrow-box">
            <v-icon large color="primary">mdi-arrow-right-thick</v-icon>
          </div>
        </v-col>

        <v-col cols="12" sm="5">
          <!-- Prices -->
          <Prices v-if="showLoader" productType="formattedProducts"></Prices>

          <!-- Loader -->
          <full-page-loader
            v-if="!showLoader"
            text="Päivitä tarvittavat kentät..."
            :fontSize="14"
            :size="50"
            :sm="6"
            type="line"
          ></full-page-loader>
        </v-col>
      </v-row>
    </div>
  </v-card>
</template>

<script>
import mixins from "../../mixins/mixins";
import Form from "./Form.vue";
import Prices from "./Prices";
import { mapState, mapGetters } from "vuex";
import FullPageLoader from "@/components/FullPageLoader";

export default {
  mixins: [mixins],

  props: {
    value: { type: Boolean, default: false },
    edit: { type: Boolean, default: false },
  },

  components: {
    Form,
    Prices,
    FullPageLoader,
  },

  computed: {
    ...mapState("rentIncrease", ["currentContract"]),
    ...mapGetters("rentIncrease", ["showLoader"]),

    getMethodBasedValues() {
      let value;

      // Elinkustannusindeksi
      if (this.currentContract.lease.rentIncreaseType == "index") {
        value = `Elinkustannusindeksin ${this.formatDateMonthName(
          this.currentContract.lease.indexStartDate
        )} pisteluvun ${this.currentContract.lease.indexStartValue} perusteella`;
      }

      if (this.currentContract.lease.rentIncreaseType == "indexPlusPercentage") {
        value = `Elinkustannusindeksin ${this.formatDateMonthName(
          this.currentContract.lease.indexStartDate
        )} pisteluvun ${this.currentContract.lease.indexStartValue} perusteella ja ${
          this.currentContract.lease.percentagePrefix || ""
        } ${this.currentContract.lease.rentIncreasePercentage} %`;
      }

      // Kuluttajaindeksi
      if (this.currentContract.lease.rentIncreaseType == "consumerIndex") {
        value = `Kuluttajaindeksin ${this.formatDateMonthName(
          this.currentContract.lease.indexStartDate
        )} pisteluvun ${this.currentContract.lease.indexStartValue} perusteella`;
      }

      if (this.currentContract.lease.rentIncreaseType == "consumerIndexPlusPercentage") {
        value = `Kuluttajaindeksin ${this.formatDateMonthName(
          this.currentContract.lease.indexStartDate
        )} pisteluvun ${this.currentContract.lease.indexStartValue} perusteella ja ${
          this.currentContract.lease.percentagePrefix || ""
        } ${this.currentContract.lease.rentIncreasePercentage} %`;
      }

      // Kiinteistön ylläpidon kustannusindeksi
      if (this.currentContract.lease.rentIncreaseType == "propertyMaintenanceIndex") {
        value = `Kiinteistön ylläpidon kustannusindeksin ${this.formatQuarterName(
          this.currentContract.lease.indexStartDate
        )} pisteluvun ${this.currentContract.lease.indexStartValue} perusteella`;
      }

      if (this.currentContract.lease.rentIncreaseType == "propertyMaintenancePlusPercentageIndex") {
        value = `Kiinteistön ylläpidon kustannusindeksin ${this.formatQuarterName(
          this.currentContract.lease.indexStartDate
        )} pisteluvun ${this.currentContract.lease.indexStartValue} perusteella ja ${
          this.currentContract.lease.percentagePrefix || ""
        } ${this.currentContract.lease.rentIncreasePercentage} %`;
      }

      // Prosentti korotus
      if (this.currentContract.lease.rentIncreaseType == "percentage") {
        const str = this.currentContract.lease.percentagePrefix;
        value = `${str ? str.charAt(0).toUpperCase() + str.slice(1) : ""} ${
          this.currentContract.lease.rentIncreasePercentage
        } %`;
      }

      // Kiinteä korotus
      if (this.currentContract.lease.rentIncreaseType == "fixed") {
        value = `Enintään ${this.currentContract.lease.rentIncreaseFixed} €`;
      }

      // Oma korotus
      if (this.currentContract.lease.rentIncreaseType == "own") {
        value = `${this.currentContract.lease.rentIncreaseOwn}`;
      }

      return value;
    },

    fixedPeriodRecurrencyInUse() {
      if (this.currentContract.baseInvoice.fixedPeriodRecurrency?.inUse) {
        return true;
      } else {
        return false;
      }
    },
  },

  methods: {
    resetValues() {
      this.$refs.form.resetForm();
    },

    validateForm() {
      return this.$refs.form.validateForm();
    },
  },
};
</script>

<style scoped>
.warning {
  border-radius: 4px;
  padding: 20px 20px 10px 20px;
  margin-bottom: 25px;
}

.wrapper {
  padding: 8px !important;
}

.info-box {
  border: 1px solid rgb(220, 220, 220);
  border-radius: 4px;
  padding: 20px 20px 10px 20px;
  margin-bottom: 25px;
}

.arrow-box {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.info-text-wrapper {
  display: flex;
}

.text-box-left {
  width: 50%;
  padding-right: 25px;
}

.text-box-right {
  width: 50%;
}

@media (max-width: 600px) {
  .info-box {
    padding: 20px 10px 5px 10px;
    margin-bottom: 25px;
  }

  .text-box-left {
    padding-right: 15px;
  }
}
</style>
